.blog-blog {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #f4f8ff;
}
.blog-container {
  margin: var(--dl-space-space-twounits);
  display: flex;
  max-width: 1000px;
  align-items: center;
  justify-content: space-between;
}
.blog-blog-post-card {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}
.blog-blog-post-card:hover {
  transform: scale(1.02);
}
.blog-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.blog-container1 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.blog-text {
  color: #0b3558;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-text001 {
  color: #0b3558;
  font-weight: 400;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-text011 {
  font-style: normal;
  font-weight: 700;
}
.blog-text024 {
  font-style: normal;
  font-weight: 700;
}
.blog-text037 {
  font-style: normal;
  font-weight: 700;
}
.blog-text062 {
  font-style: normal;
  font-weight: 700;
}
.blog-text089 {
  font-style: normal;
  font-weight: 700;
}
.blog-text101 {
  font-style: normal;
  font-weight: 700;
}
.blog-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.blog-profile {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.blog-image1 {
  width: auto;
  height: var(--dl-size-size-small);
  margin: 0px;
  padding: 0px;
  object-fit: cover;
}
.blog-text122 {
  color: var(--dl-color-gray-700);
  font-weight: 300;
}
.blog-container3 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blog-container4 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media(max-width: 991px) {
  .blog-blog {
    flex-direction: column;
  }
  .blog-container {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .blog-image {
    height: 350px;
  }
  .blog-container3 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .blog-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .blog-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
