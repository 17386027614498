.features-features {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #f4f8ff;
}
.features-container {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.features-text {
  color: #0b3558;
  width: 100%;
  font-size: 2rem;
  max-width: 1400px;
  margin-bottom: var(--dl-space-space-unit);
}
.features-text1 {
  color: #0f6bff;
  width: 100%;
  font-size: 1.5rem;
  max-width: 1400px;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.features-text2 {
  width: 100%;
  font-size: 1rem;
  max-width: 1400px;
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
}
.features-text3 {
  color: #0b3558;
  width: 100%;
  font-size: 1.5rem;
  max-width: 1400px;
  margin-bottom: var(--dl-space-space-unit);
}
.features-container1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  max-width: 1400px;
  grid-template-columns: 1fr 1fr;
}
.features-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.features-icon {
  fill: var(--dl-color-gray-500);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.features-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.features-text4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.features-text5 {
  font-size: 1rem;
}
.features-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.features-icon2 {
  fill: var(--dl-color-gray-500);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.features-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.features-text6 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.features-text7 {
  font-size: 1rem;
}
.features-container4 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  max-width: 1400px;
  align-self: center;
  align-items: center;
  justify-content: flex-end;
}
.features-text8 {
  font-size: 1rem;
}
.features-link {
  color: white;
  font-size: 1rem;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #0f6bff;
}
.features-link:hover {
  transform: scale(1.02);
  background-color: #0a4ab2;
}
@media(max-width: 1600px) {
  .features-features {
    align-items: center;
    justify-content: center;
  }
  .features-text {
    width: 100%;
  }
  .features-text1 {
    width: 100%;
  }
  .features-text2 {
    width: 100%;
  }
  .features-text3 {
    width: 100%;
  }
}
@media(max-width: 1200px) {
  .features-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .features-container4 {
    margin-top: var(--dl-space-space-threeunits);
  }
  .features-link {
    color: white;
    border-width: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    background-color: #0f6bff;
  }
}
@media(max-width: 767px) {
  .features-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .features-container1 {
    grid-template-columns: 1fr;
  }
  .features-feature-card {
    flex-direction: row;
  }
  .features-container2 {
    flex-direction: column;
  }
  .features-feature-card1 {
    flex-direction: row;
  }
  .features-container3 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .features-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .features-container2 {
    flex-direction: column;
  }
  .features-container3 {
    flex-direction: column;
  }
  .features-link {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
