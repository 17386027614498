.hero-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: 100%;
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #f4f8ff;
}
.hero-container {
  flex: 1;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.hero-container1 {
  flex: 1;
  width: auto;
  display: flex;
  max-width: 1400px;
  margin-right: var(--dl-space-space-twounits);
  padding-right: 0px;
  flex-direction: column;
}
.hero-heading {
  color: rgb(0, 0, 0);
  width: auto;
  font-size: 2.5rem;
  max-width: auto;
}
.hero-text {
  color: #0b3558;
}
.hero-text1 {
  color: #0f6bff;
}
.hero-text2 {
  color: #0b3558;
}
.hero-text3 {
  color: #0b3558;
}
.hero-text4 {
  font-size: 1rem;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.hero-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.hero-link {
  color: #0f6bff;
  cursor: pointer;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: #0f6bff;
  border-width: 1px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.hero-link:hover {
  color: #0f6bff;
  background-color: rgba(15, 107, 255, 0.04);
}
.hero-link1 {
  color: white;
  cursor: pointer;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  border-color: transparent;
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #0f6bff;
}
.hero-link1:hover {
  border-color: var(--dl-color-gray-black);
  background-color: #0a4ab2;
}
.hero-image {
  width: 50%;
  height: auto;
  object-fit: cover;
}
@media(max-width: 1600px) {
  .hero-hero {
    max-width: 100%;
    align-items: center;
    border-color: rgba(0, 0, 0, 0.3);
    border-width: 0px;
    justify-content: center;
    border-bottom-width: 0px;
  }
  .hero-container {
    flex: 1;
    width: auto;
  }
  .hero-container1 {
    width: auto;
  }
  .hero-heading {
    color: rgb(0, 0, 0);
    font-size: 2rem;
  }
  .hero-text {
    color: #0b3558;
  }
  .hero-text1 {
    color: #0f6bff;
  }
  .hero-text2 {
    color: #0b3558;
  }
  .hero-text3 {
    color: #0b3558;
  }
  .hero-text4 {
    font-size: 1rem;
  }
}
@media(max-width: 1200px) {
  .hero-heading {
    color: rgb(0, 0, 0);
    font-size: 2rem;
  }
  .hero-text {
    color: #0b3558;
  }
  .hero-text1 {
    color: #0f6bff;
  }
  .hero-text2 {
    color: #0b3558;
  }
  .hero-text3 {
    color: #0b3558;
  }
  .hero-text4 {
    font-size: 1rem;
  }
}
@media(max-width: 991px) {
  .hero-hero {
    flex-direction: column;
  }
  .hero-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .hero-heading {
    text-align: center;
  }
  .hero-text4 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .hero-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .hero-text4 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .hero-image {
    width: 80%;
  }
}
@media(max-width: 479px) {
  .hero-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .hero-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .hero-btn-group {
    flex-direction: column;
  }
  .hero-link1 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
