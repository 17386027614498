.info-hero-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.info-hero-container {
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: column;
}
.info-hero-text {
  color: #0b3558;
  font-size: 2.5rem;
}
.info-hero-text1 {
  font-size: 1rem;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
}
.info-hero-text2 {
  font-size: 1rem;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.info-hero-link {
  color: white;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #0f6bff;
}
.info-hero-link:hover {
  transform: scale(1.02);
  background-color: #0a4ab2;
}
.info-hero-image {
  width: auto;
  max-width: 700px;
  margin-top: var(--dl-space-space-twounits);
}
@media(max-width: 1600px) {
  .info-hero-text {
    color: #0b3558;
  }
  .info-hero-image {
    width: auto;
  }
}
@media(max-width: 1200px) {
  .info-hero-text {
    font-size: 2rem;
  }
  .info-hero-text1 {
    font-size: 1rem;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
  }
  .info-hero-text2 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .info-hero-link {
    color: white;
    border-width: 0px;
    border-radius: var(--dl-radius-radius-radius8);
    background-color: #0f6bff;
  }
  .info-hero-image {
    width: 80%;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 991px) {
  .info-hero-hero {
    flex-direction: column;
  }
  .info-hero-container {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .info-hero-text {
    text-align: center;
  }
  .info-hero-image {
    width: 80%;
  }
}
@media(max-width: 767px) {
  .info-hero-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .info-hero-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .info-hero-container {
    margin-bottom: var(--dl-space-space-unit);
  }
  .info-hero-link {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
